import { Box } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { SectionHeading } from '../Text/SectionHeading'

interface ContentTemplateProps {
  title: string
}

export const ContentTemplate: FC<PropsWithChildren<ContentTemplateProps>> = ({ children, ...props }) => {
  const { title } = props
  return (
    <Box component='div' sx={{ padding: '38px', width: '100%' }}>
      <SectionHeading size='h4'>{title}</SectionHeading>
      {children}
    </Box>
  )
}