import { InfoActionCard } from '../../../../components/Cards/InfoActionCard'
import React, { createRef, useState, useEffect, FC } from 'react'
import { SectionHeading } from '../../../../components/Text/SectionHeading'
import { CardParagraph } from '../../../../components/Text/CardParagraph'
import { FormControlSingleRow } from '../../../../components/Form/FormControlSingleRow'
import { alpha, Box, Button, Divider, ListItem, ListItemText, styled } from '@mui/material'
import { StyledFormLabel } from '../../../../components/Form/StyledFormLabel'
import { AMDOutput } from '@/models/AMD/output/AMD.output'
import { AMDsStepModal } from './AMDsStepModal'
import API from '../../../../api'
import { APIResponse } from '@/types/API/response.output'
import { ArrayOutput } from '@/types/API/array.output'
import { DeploymentOutput } from '@/models/Deployment/output/deployment.output'
import { ListWithCircularProgress } from '../../../../components/List/ListWithCircularProgress'
import { FormTextField } from '../../../../components/TextFields/FormText'
import { InlineAsterisk } from '../../../../components/Text/InlineAsterisk'
import { useForm } from 'react-hook-form'
import { ClaimAMDInput } from '@/models/AMD/input/claimAMD.input'
import { MeasurementSpecOutput } from '@/models/MeasurementSpec/output/measurementSpec.output'

interface AMDsStepProps {
  targetDeploymentState: [DeploymentOutput | '', React.Dispatch<React.SetStateAction<DeploymentOutput | ''>>]
  targetMeasurementSpecState: [MeasurementSpecOutput | '', React.Dispatch<React.SetStateAction<MeasurementSpecOutput | ''>>]
  setContinueEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

export const AMDsStep: FC<AMDsStepProps> = (props) => {
  const { 
    targetDeploymentState: [targetDeployment,],
    targetMeasurementSpecState: [targetMeasurementSpec,],
    setContinueEnabled
  } = props

  const modalOpenState = useState(false)
  // const [, setModalOpen] = modalOpenState

  const [fetchingAMDs, setFetchingAMDs] = useState(false)

  const [AMDOutputs, setAMDOutputs] = useState<Array<AMDOutput>>([])

  const { register, handleSubmit, setValue, getValues } = useForm<ClaimAMDInput>()

  let ref: React.RefObject<HTMLLIElement> = createRef()

  const handleAddAMD = (data: ClaimAMDInput) => {
    setFetchingAMDs(true)
    API.ClaimAmd<APIResponse<AMDOutput>>(data)
      .then((response) => {
        const claimedAMD = response.data.data
        if (claimedAMD) setAMDOutputs([...AMDOutputs, claimedAMD])
        setFetchingAMDs(false)
      })
      .catch((err) => {
        setFetchingAMDs(false)
      })
    setValue('idPhrase', '')
  }

  useEffect(() => {
    if (targetDeployment === '') return
    setValue('deploymentId', targetDeployment._id)

    setFetchingAMDs(true)
    API.GetAMDsByDeploymentId<APIResponse<ArrayOutput<AMDOutput>>>(targetDeployment._id)
      .then((response) => {
        setAMDOutputs(() => [...response.data.data.items])
        setFetchingAMDs(false)
      })
  }, [setValue, targetDeployment])

  useEffect(() => {
    if (targetMeasurementSpec === '') return
    setValue('configurationId', targetMeasurementSpec._id)
  })

  useEffect(() => {
    if (AMDOutputs.length !== 0) setContinueEnabled(true)
    else setContinueEnabled(false)
  }, [AMDOutputs, setContinueEnabled])

  return (
    <>
      <InfoActionCard>
        <SectionHeading size='h6'>AMDs</SectionHeading>
        <CardParagraph sx={{ marginTop: '16px' }}>You can add <strong>AMDs</strong> to a <strong>Deployment</strong> one by one</CardParagraph>
        <FormControlSingleRow>
          <Box component='div' sx={{ gridArea: 'left' }}>
            <StyledFormLabel id='amds-view-label'>AMDs in this Deployment</StyledFormLabel>
            <ListWithCircularProgress
              loading={fetchingAMDs}
              data={AMDOutputs as []}
              itemName='AMD'
              collectionName='Deployment'
              liHeightRef={ref}
            >
              { AMDOutputs.map((AMD, ix) => (
                <Box component='div' key={`list-item-AMD-${AMD._id}`}>
                  <ListItem ref={ref}>
                    <ListItemText primary={AMD.idPhrase} />
                  </ListItem>
                  { ix !== AMDOutputs.length - 1 && <Divider variant='middle' component='li' /> }
                </Box>
              ))}
            </ListWithCircularProgress>
          </Box>
          <Box component='form' onSubmit={handleSubmit(handleAddAMD)} sx={{ display: 'flex', flexDirection: 'column' }}>
            <StyledFormLabel id='amd-setup-id-label'>3 Word ID<InlineAsterisk /></StyledFormLabel>
            <Box component='div' sx={{ flexDirection: 'row', width: '100%' }}>
              <FormTextField sx={{ marginTop: '0px', width: '67%' }} placeholder='fezzy-fizzy-fuzzy' margin='normal' {...register('idPhrase', {required: true, value: getValues().idPhrase })} />
              <StyledButton variant='contained' sx={{ marginTop: '0px', width: '29%', marginLeft: '4%' }} type='submit'>Add AMD +</StyledButton>
            </Box>
          </Box>
          {/* <StyledButton onClick={handleAddAMDs} variant='outlined' sx={{ gridArea: 'right', position: 'absolute', width: '100%', bottom: '0px' }}>
            Add AMD(s) +
          </StyledButton> */}
        </FormControlSingleRow>
      </InfoActionCard>
      <AMDsStepModal modalOpenState={modalOpenState}/>
    </>
  )
}

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  height: '40px',
  backgroundColor: theme.palette.background.default,
  color: 'white',
  ':hover': {
    background: alpha(theme.palette.background.default, 0.8),
  }
}))