import { Theme } from '@emotion/react'
import { SxProps, Typography } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

interface CardParagraphProps {
  sx?: SxProps<Theme>
}

export const CardParagraph: FC<PropsWithChildren<CardParagraphProps>> = ({ children, ...props }) => {
  const { sx } = props
  return (
    <Typography variant='body1' component='div' paragraph sx={ sx }>{children}</Typography>
  )
}