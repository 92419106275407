import { SectionHeading } from '../../../../components/Text/SectionHeading'
import { Box, MenuItem, SelectChangeEvent } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { CardParagraph } from '../../../../components/Text/CardParagraph'
import { StyledSelect } from '../../../../components/Select/StyledSelect'
import { DeploymentOutput } from '@/models/Deployment/output/deployment.output'
import { APIResponse } from '@/types/API/response.output'
import API from '../../../../api'
import { ArrayOutput } from '@/types/API/array.output'
import { DeploymentStepModal } from './DeploymentStepModal'
import { InfoActionCard } from '../../../../components/Cards/InfoActionCard'
import { FormControlSingleRow } from '../../../../components/Form/FormControlSingleRow'
import { StyledFormLabel } from '../../../../components/Form/StyledFormLabel'
import { InlineAsterisk } from '../../../../components/Text/InlineAsterisk'
import { StyledButton } from '../../../../components/Buttons/StyledButton'

interface DeploymentStepProps {
  targetDeploymentState: [DeploymentOutput | '', React.Dispatch<React.SetStateAction<DeploymentOutput | ''>>]
  setContinueEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

export const DeploymentStep: FC<DeploymentStepProps> = (props) => {
  const { targetDeploymentState: [targetDeployment, setTargetDeployment], setContinueEnabled } = props

  const deploymentOutputsState = useState<Array<DeploymentOutput>>([])
  const [deploymentOutputs, setDeploymentOutputs] = deploymentOutputsState

  const [fetchingDeployments, setFetchingDeployments] = useState(false)

  const modalOpenState = useState(false)
  const [, setModalOpen] = modalOpenState

  const handleSetDeployment = (event: SelectChangeEvent<unknown>) => {
    setTargetDeployment(event.target.value as DeploymentOutput)
  }
  const handleNewDeployment = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    setFetchingDeployments(true)
    API.GetMyDeployments<APIResponse<ArrayOutput<DeploymentOutput>>>()
      .then((response) => {
        setDeploymentOutputs(() => [...response.data.data.items])
        setFetchingDeployments(false)
      })
  }, [setDeploymentOutputs])

  // control the outer PRIMARY CTA
  useEffect(() => {
    setContinueEnabled(targetDeployment === '' ? false : true)
  }, [setContinueEnabled, targetDeployment])

  return (
    <>
      <InfoActionCard>
        <SectionHeading size='h6'>Deployment</SectionHeading>
        <CardParagraph sx={{ marginTop: '16px' }}>AMDs must be associated with a <strong>Deployment</strong> as this is used to group your network topology. You must ensure that they are situated within range of a <strong>Gateway</strong>.</CardParagraph>
        <FormControlSingleRow>
          <Box component='div' sx={{ gridArea: 'left' }}>
            <StyledFormLabel id='deployment-select-label'>Deployment<InlineAsterisk /></StyledFormLabel>
            <StyledSelect
              renderValue={(value) => {
                const deployment = value as DeploymentOutput | ''
                if (deploymentOutputs?.length > 0) {
                  if (deployment !== '') {
                    return deployment.name
                  }
                  return 'Select Deployment'
                }
                return 'No Deployments'
              }}
              loading={fetchingDeployments}
              disabled={deploymentOutputs?.length === 0 || fetchingDeployments}
              labelId='deployment-select-label'
              displayEmpty
              sx={{ height: '40px', borderRadius: '10px', position: 'relative' }}
              value={targetDeployment}
              onChange={handleSetDeployment}
            >
              <MenuItem disabled>Please Select a Deployment</MenuItem>
              {deploymentOutputs?.map((deploymentOutput) => (
                // HACK: deployment output as any. Field only supports number by li MDN docs.
                // React signature is (property) value?: string | number | readonly string[] | undefined
                // Seems safe because it is using data-value under the hood (data-* MDN documented)
                // renderValue picks it up whole
                <MenuItem key={deploymentOutput._id} value={deploymentOutput as any}>{deploymentOutput.name}</MenuItem>
              ))}
            </StyledSelect>
          </Box>
          <StyledButton onClick={handleNewDeployment} variant='outlined' sx={{ gridArea: 'right', position: 'absolute', width: '100%', bottom: '0px' }}>
            New Deployment +
          </StyledButton>
        </FormControlSingleRow>
      </InfoActionCard>
      <DeploymentStepModal
        deploymentOutputsState={deploymentOutputsState}
        modalOpenState={modalOpenState}
        setFetchingDeployments={setFetchingDeployments}
        setTargetDeployment={setTargetDeployment} />
    </>
  )
}