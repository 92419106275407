import { alpha, Box, Button, styled } from '@mui/material'

export const CTAContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
})

export const StyledButtonPrimary = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  flex: 1,
  '&.Mui-disabled': {
    backgroundColor: alpha('#A59B91', 0.2),
  }
}))

export const StyledButtonAlert = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  flex: 1,
  backgroundColor: theme.palette.error.main,
  ':hover': {
    backgroundColor: theme.palette.error.dark
  }
}))

export const StyledButtonSecondary = styled(StyledButtonPrimary)(({ theme }) => ({
  color: '#1E1E1E',
  border: '2px solid #1E1E1E',
  backgroundColor: alpha('#1E1E1E', 0.05),
  ':hover': {
    border: '2px solid #1E1E1E',
    backgroundColor: alpha('#1E1E1E', 0.15),
  },
}))