import { Box, Button, Divider, ListItemText, Menu, MenuItem, styled, SvgIcon, Typography } from '@mui/material'
import { FunctionComponent, MouseEvent, useState } from 'react'

import { ReactComponent as ZoneosLogoLight } from '../../images/zoneos-logo-light.svg'
import { ReactComponent as DropdownArrowLight } from '../../images/Button/dropdown-arrow-light.svg'
import { useTypedDispatch, useTypedSelector } from '../../state/hooks'
import { signOutThunk } from '../../state/store/User/user.slice'


export const Header: FunctionComponent = () => {
  const user = useTypedSelector(state => state.user)
  const dispatch = useTypedDispatch()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Container component='div'>
      <SvgIcon component={ZoneosLogoLight} />
      <StyledButton
        variant='outlined'
        onClick={handleClick}
        id='profile-button'
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <Typography variant='body1'>
          {user.name?.split(' ', 1)?.[0] ?? 'User'}
        </Typography>
        <Box component='div' sx={{ position: 'absolute', right: '0px', margin: '2px' }}>
          <SvgIcon component={DropdownArrowLight} sx={{ height: '100%', width: '40%' }} />
        </Box>
      </StyledButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose} PaperProps={{ style: { border: '1px solid #000', marginTop: '16px', minWidth: '300px' } }}>
        <MenuItem>
          <ListItemText>{user.name ?? 'User'}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText>Account Settings</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { handleClose(); dispatch(signOutThunk()) }}>
          <ListItemText>Sign Out</ListItemText>
        </MenuItem>
      </StyledMenu>
    </Container>
  )
}

const StyledMenu = styled(Menu)(_ => ({
  'paper': {
    border: '40px solid #000'
  }
}))

const Container = styled(Box)(_ => ({
  display: 'flex',
  width: '100%',
  gridArea: 'header',
  backgroundColor: 'var(--dark-color)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  alignItems: 'center',
  padding: '0px 32px',
  justifyContent: 'space-between',
}))

const StyledButton = styled(Button)(_ => ({
  minWidth: '150px',
  height: '55%',
  paddingX: '25px',
  border: '2px solid var(--light-color)',
  color: 'var(--light-color)',
  '&:hover': {
    border: '2px solid var(--light-dark-color)',
  },
  display: 'flex',
  justifyContent: 'center',
}))