import { Card, styled } from '@mui/material';

export const OnboardingCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: '40vw'
  },
  margin: '6vh',
  backgroundColor: 'var(--light-color)',
  borderRadius: 20,
  boxShadow: '4px -1px 20px #F5EBE1'
}))
