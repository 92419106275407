import { FormTextArea, FormTextField } from '../../../../components/TextFields/FormText'
import { Box, FormControl, FormLabel, styled } from '@mui/material'
import { FC } from 'react'
import { ModalContent } from '../../../../components/Modal/ModalContent'
import { useForm } from 'react-hook-form'
import API from '../../../../api'
import { APIResponse } from '@/types/API/response.output'
import { CreateDeploymentInput } from '@/models/Deployment/input/createDeployment.input'
import { DeploymentOutput } from '@/models/Deployment/output/deployment.output'

interface DeploymentStepModalProps {
  deploymentOutputsState: [DeploymentOutput[], React.Dispatch<React.SetStateAction<DeploymentOutput[]>>]
  modalOpenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  setFetchingDeployments: React.Dispatch<React.SetStateAction<boolean>>
  setTargetDeployment: React.Dispatch<React.SetStateAction<DeploymentOutput | ''>>
}

export const DeploymentStepModal: FC<DeploymentStepModalProps> = (props) => {
  const {
    deploymentOutputsState: [deploymentOutputs, setDeploymentOutputs],
    modalOpenState: [modalOpen, setModalOpen],
    setFetchingDeployments,
    setTargetDeployment
  } = props

  const { register, handleSubmit } = useForm<CreateDeploymentInput>()

  const handleCancelModal = () => {
    setModalOpen(false)
  }

  const handleCreateDeploymentSubmit = (data: CreateDeploymentInput) => {
    setFetchingDeployments(true)
    API.CreateDeployment<APIResponse<DeploymentOutput>>(data)
      .then((response) => {
        const newDeploymentOutput = response.data.data
        setDeploymentOutputs([...deploymentOutputs, newDeploymentOutput])
        setTargetDeployment(newDeploymentOutput)
        setFetchingDeployments(false)
      })
    setModalOpen(false)
  }

  return (
    <ModalContent
      heading='Create New Deployment'
      primaryText='Continue'
      secondaryText='Cancel'
      handleSecondary={handleCancelModal}
      open={modalOpen}
      handleClose={handleCancelModal}
      form={handleSubmit(handleCreateDeploymentSubmit)}
    >
      <StyledModalFormControl sx={{ marginTop: '24px' }}>
        <Box component='div' sx={{ gridArea: 'name' }}>
          <StyledFormLabel id='deployment-setup-name-label'>Name<Box component='span' color='red'>*</Box></StyledFormLabel>
          <FormTextField sx={{ marginTop: '6px', width: '100%' }} placeholder='Deployment Name' margin='normal' {...register('name', { required: true })} />
        </Box>
        <Box component='div' sx={{ gridArea: 'siteAddr' }}>
          <StyledFormLabel id='deployment-setup-siteaddr-label'>Site Address<Box component='span' color='red'>*</Box></StyledFormLabel>
          <FormTextField sx={{ marginTop: '6px', width: '100%' }} placeholder='Address or Postcode' margin='normal' {...register('locationAddress', { required: true })} />
        </Box>
        <Box component='div' sx={{ gridArea: 'desc' }}>
          <StyledFormLabel id='deployment-setup-desc-label'>Description<Box component='span' color='red'>*</Box></StyledFormLabel>
          <FormTextField sx={{ marginTop: '6px', width: '100%' }} placeholder='Description' margin='normal' {...register('description', { required: true })} />
        </Box>
        <Box component='div' sx={{ gridArea: 'notes' }}>
          <StyledFormLabel id='deployment-setup-notes-label'>Notes</StyledFormLabel>
          <FormTextArea multiline rows={2} sx={{ marginTop: '6px', width: '100%' }} placeholder='Enter any notes' margin='normal' {...register('notes')} />
        </Box>
      </StyledModalFormControl>
    </ModalContent>
  )
}

const StyledModalFormControl = styled(FormControl)(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas:
    `'name siteAddr'
     'desc .'
     'notes notes'`,
  gridTemplateRows: 'auto auto auto',
  gridTemplateColumns: '49% 49%',
  columnGap: '2%',
}))

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: 'var(--dark-color)',
  fontWeight: 'bold',
  paddingLeft: '8px',
  '&.Mui-focused': {
    color: 'var(--dark-color)'
  }
}))