import { FormControl, styled } from '@mui/material'

export const FormControlSingleRow = styled(FormControl)(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas:
    `'left right'`,
  gridTemplateRows: 'auto',
  gridTemplateColumns: '49% 49%',
  position: 'relative',
  columnGap: '2%'
}))