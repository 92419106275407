import { FunctionComponent, useEffect } from "react"

import { Button, Divider, Link, Typography, Box } from "@mui/material"
import { FormTextField } from "../../../components/TextFields/FormText"
import { useForm } from "react-hook-form"
import { SigninInput } from "../../../types/Onboarding"
import { useTitle } from '../Onboarding'
import { Link as RouterLink } from 'react-router-dom'

import { useTypedDispatch, useTypedSelector } from '../../../state/hooks/index'
import { HydrationStatus, signIn } from '../../../state/store/User/user.slice'
import { LocalStorageDriver } from "../../../state/localStorage"

export const Signin: FunctionComponent = () => {
  const dispatch = useTypedDispatch()
  const user = useTypedSelector(state => state.user)

  const { register, handleSubmit } = useForm<SigninInput>()

  const { setTitle } = useTitle()
  useEffect(() => {
    setTitle('Sign In')
  })
  
  const onSubmit = (data: SigninInput) => {
    dispatch(signIn(data))
      .unwrap()
      .then((response) => {
        const {email, loginToken, name} = response.data
        LocalStorageDriver.setAccountData(name, email, loginToken)
      })
  }
  
  return (
    <Box component='div' sx={{ width: '100%'}}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ display: "flex", flexDirection: "column", marginBottom: "28px"}}>
        <FormTextField placeholder="Email" margin="normal" {...register("email", { required: true })} />
        <FormTextField placeholder="Password" margin="normal" type="password" {...register("password", { required: true })} />
        <Button variant="contained" type="submit" sx={{ marginTop: "16px", marginBottom: "8px", height: "40px" }}>Continue</Button>
        <Link component={RouterLink} to='resetPassword' variant="subtitle1" sx={{ marginTop: "16px", alignSelf: "center" }} color="primary">Forgot Password?</Link>
      </Box>
      { user.status === HydrationStatus.FAILED &&
        <Typography variant="h5">
          The details you have entered are incorrect. Please try again or reset password.
        </Typography>
      }
      <Divider variant="fullWidth" sx={{ width: "100%" }} />
      <Box component="div" sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", marginTop: "30px" }}>
        <Typography variant="subtitle2">
          Don't have an account?
        </Typography>
        <Button 
          component={RouterLink}
          to='/signup'
          variant='contained'
          sx={{
            marginTop: "16px",
            marginBottom: "8px",
            width: "100%", 
            height: "40px", 
            color: "primary.main",
            backgroundColor: "primary.light",
            border: 1,
            borderColor: "primary.main",
            '&:hover': {
              backgroundColor: "primary.light",
            }
          }}>Sign Up</Button>
      </Box>
    </Box>
  )
}
