import { alpha, Box, Button, Card, Modal, styled, Typography } from '@mui/material'
import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react'
import { CTAContainer, StyledButtonAlert } from '../Buttons/CTA'
import { SectionHeading } from '../Text/SectionHeading'

interface ModalProps {
  heading: string
  prompt: string
  primaryText: string
  secondaryText: string
  open: boolean
  handleClose: Dispatch<SetStateAction<boolean>>
  handlePrimary?: () => void
  handleSecondary?: () => void
}

export const ModalConfirm: FC<PropsWithChildren<ModalProps>> = ({ children, ...props }) => {
  const { heading, prompt, open, handleClose, handlePrimary, handleSecondary, primaryText, secondaryText } = props
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <ModalCard>
        <Container component='div'>
          <Box component='div' sx={{ padding: '24px', paddingBottom: '0px' }}>
            <SectionHeading size='h5'>{heading}</SectionHeading>
            <Box component='div' sx={{ marginTop: '24px' }}>
              <Typography variant='body1'>{prompt}</Typography>
            </Box>
          </Box>
          <CTAContainer sx={{ marginY: '28px', marginX: '14px' }}>
            <StyledButtonAlert type='button' onClick={handlePrimary} variant='contained' sx={{ marginX: '14px' }}>{primaryText}</StyledButtonAlert>
            <StyledButtonSecondary onClick={handleSecondary} variant='outlined' sx={{ marginX: '14px' }}>{secondaryText}</StyledButtonSecondary>
          </CTAContainer>
        </Container>
      </ModalCard>
    </Modal>
  )
}

const ModalCard = styled(Card)(({ theme }) => ({
  width: '50%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '20px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.card.bg,
  ':focus-visible': {
    outline: '#1E1E1E auto 1px'
  }
}))

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledButtonPrimary = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  flex: 1,
  '&.Mui-disabled': {
    backgroundColor: alpha('#A59B91', 0.2),
  }
}))

export const StyledButtonSecondary = styled(StyledButtonPrimary)(({ theme }) => ({
  color: '#1E1E1E',
  border: '2px solid #1E1E1E',
  backgroundColor: alpha('#1E1E1E', 0.05),
  ':hover': {
    border: '2px solid #1E1E1E',
    backgroundColor: alpha('#1E1E1E', 0.15),
  },
}))