import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FormTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'var(--dark-color)'
  },
  '& .MuiOutlinedInput-root': {
    height: '40px',
    borderRadius: '10px',
    '& fieldset': {
      borderColor: 'var(--light-dark-color)',
    },
    '&:hover fieldset': {
      borderColor: 'var(--medium-dark-color)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--dark-color)',
    },
  },
  '& .MuiOutlinedInput-input': {
    '::placeholder': {
      color: '#CDC3B9',
      opacity: 1
    }
  }
})

export const FormTextArea = styled(FormTextField)({
  '& .MuiOutlinedInput-root': {
    height: 'auto',
  }
})