import { Box, MenuItem, MenuList, styled, Typography } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

export const Sidebar: FC = () => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const routeToIndexMap = useMemo(() => new Map([
    ['overview', 0],
    ['deployments', 1],
    ['gateways', 2],
    ['AMDs', 3],
  ]), [])

  const menuItemsToRouteMap = useMemo(() => new Map([
    ['Overview', 'overview'],
    ['Deployments', 'deployments'],
    ['Gateways', 'gateways'],
    ['AMDs', 'AMDs']
  ]), [])

  const handleMenuAction = (i: number, menuItem: string) => {
    setSelectedIndex(i)
    const targetRoute = menuItemsToRouteMap.get(menuItem)
    if (targetRoute) navigate(targetRoute)
  }

  useEffect(() => {
    const currentRoute = location.pathname.split('/')?.[1] ?? ''
    setSelectedIndex(routeToIndexMap.get(currentRoute) ?? null)
  }, [location.pathname, routeToIndexMap])

  return (
    <Container>
      <MenuList sx={{
        width: '100%',
        padding: '20px',
      }}>
        {[...menuItemsToRouteMap.keys()].map((menuItem, i) => {
          const topEdgeOfMenu = i === 0
          const bottomEdgeOfMenu = i === (menuItem.length - 1)
          const selected = i === selectedIndex

          return (
            <StyledMenuItem
              key={menuItem}
              selected={selected}
              onClick={() => handleMenuAction(i, menuItem)}
              topEdge={topEdgeOfMenu}
              bottomEdge={bottomEdgeOfMenu}
            >
              <Typography fontWeight={selected ? 'bold' : 'light'} >
                  {menuItem}
              </Typography>
            </StyledMenuItem>
          )
        })}
      </MenuList>
    </Container>
  )
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gridArea: 'sidebar',
  width: '100%',
  height: '100%',
  borderRight: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.sidebar.bg
}))

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'topEdge' && prop !== 'bottomEdge'
})<{topEdge?: boolean, bottomEdge?: boolean}>(({ theme, topEdge, bottomEdge }) => ({
  ...(topEdge && {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  }),
  ...(bottomEdge && {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  }),
  fontWeight: 'bold',
  '&.Mui-selected': {
    ':hover': {
      backgroundColor: theme.sidebar.selectedHover
    },
    backgroundColor: theme.sidebar.selected,
  },
}))