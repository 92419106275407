import { Box, Card, Modal, styled } from '@mui/material'
import { Dispatch, FC, FormEventHandler, PropsWithChildren, SetStateAction } from 'react'
import { CTAContainer, StyledButtonPrimary, StyledButtonSecondary } from '../Buttons/CTA'
import { SectionHeading } from '../Text/SectionHeading'

interface ModalProps {
  heading?: string
  primaryText: string
  secondaryText: string
  handlePrimary?: () => void
  handleSecondary: () => void
  open: boolean
  handleClose: Dispatch<SetStateAction<boolean>>
  form?: FormEventHandler<HTMLDivElement>
}

export const ModalContent: FC<PropsWithChildren<ModalProps>> = ({ children, ...props }) => {
  const { heading, handlePrimary, handleSecondary, open, handleClose, primaryText, secondaryText, form } = props

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <ModalCard>
        <Container component='form' onSubmit={form}>
          <Box component='div' sx={{ padding: '24px', paddingBottom: '0px' }}>
            {heading && <SectionHeading size='h5'>{heading}</SectionHeading>}
            {children}
          </Box>
          <CTAContainer sx={{ marginY: '28px', marginX: '14px' }}>
            <StyledButtonPrimary type={form ? 'submit' : 'button'} onClick={handlePrimary ? handlePrimary : undefined} variant='contained' sx={{ marginX: '14px' }}>{primaryText}</StyledButtonPrimary>
            <StyledButtonSecondary onClick={handleSecondary} variant='outlined' sx={{ marginX: '14px' }}>{secondaryText}</StyledButtonSecondary>
          </CTAContainer>
        </Container>
      </ModalCard>
    </Modal>
  )
}

const ModalCard = styled(Card)(({ theme }) => ({
  width: '50%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '20px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.card.bg,
  ':focus-visible': {
    outline: '#1E1E1E auto 1px'
  }
}))

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))