import { FunctionComponent, useEffect, useState } from "react"

import { Button, Divider, Typography, Box } from "@mui/material"
import { FormTextField } from "../../../components/TextFields/FormText"
import { useForm } from "react-hook-form"
import { SignupInput, SignupOutput } from "../../../types/Onboarding"
import API from "../../../api"
import { useTitle } from '../Onboarding'
import { Link as RouterLink } from 'react-router-dom'

const log = console.log

export const Signup: FunctionComponent = () => {

  const { register, handleSubmit } = useForm<SignupInput>()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formError, setFormError] = useState(false)

  const { setTitle } = useTitle()
  useEffect(() => {
    setTitle('Sign Up')
  })
  
  const onSubmit = (data: SignupInput) => {
    API.SignUp<SignupOutput>(data)
      .then((response) => {
        setFormSubmitted(true)
        setFormError(false)
      })
      .catch((error) => {
        setFormError(true)
        log("Signup Error: ", error)
      })
  }
  
  return (
    <Box component='div' sx={{ width: '100%'}}>
      { !formSubmitted ?
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ display: "flex", flexDirection: "column", marginBottom: "28px"}}>
          <FormTextField placeholder="Name" margin="normal" {...register("name", { required: true })} />
          <FormTextField placeholder="Email" margin="normal" {...register("email", { required: true })} />
          <FormTextField placeholder="Password" margin="normal" type="password" {...register("password", { required: true })} />
          <Button variant="contained" type="submit" sx={{ marginTop: "16px", marginBottom: "8px", height: "40px" }}>Continue</Button>          
        </Box>
        :
        <Typography variant="h5">
          Please check your email and click on the verification link.
        </Typography>
      }
      { formError &&
        <Typography variant="h5">
          This email has already been registered
        </Typography>
      }
      <Divider variant="fullWidth" sx={{ width: "100%" }} />
      <Box component="div" sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", marginTop: "30px" }}>
        <Typography variant="subtitle2">
          Already have an account?
        </Typography>
        <Button
          component={RouterLink}
          to='/signin'
          variant="contained" 
          sx={{
            marginTop: "16px",
            marginBottom: "8px",
            width: "100%", 
            height: "40px", 
            color: "primary.main", 
            backgroundColor: "primary.light",
            border: 1,
            borderColor: "primary.main",
            '&:hover': {
              backgroundColor: "primary.light",
            }
          }}>Sign In</Button>
      </Box>
    </Box>
  )
}
