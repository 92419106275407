import { ContentTemplate } from '../../components/Templates/ContentTemplate'
import { FC, useEffect, useState } from 'react'
import { StyledButton } from '../../components/Buttons/StyledButton'
import { alpha, Box, Button, CircularProgress, IconButton, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router'
import { InfoActionCard } from '../../components/Cards/InfoActionCard'
import API from '../../api'
import { APIResponse } from '@/types/API/response.output'
import { AMDSummaryOutput } from '@/models/AMD/output/AMDSummary.output'
import { ArrayOutput } from '@/types/API/array.output'

import DeleteIcon from '@mui/icons-material/Delete';
import { ModalConfirm } from '../../components/Modal/ModalConfirm'


export const AMDs: FC = () => {

  const [fetchingAMDsSummary, setFetchingAMDsSummary] = useState(false)
  const [fetchingCSV, setFetchingCSV] = useState(false)
  const [AMDSummaryOutputs, setAMDSummaryOutputs] = useState<Array<AMDSummaryOutput>>([])

  const [modalOpen, setModalOpen] = useState(false)
  const [selectedAMDForRemoval, setSelectedAMDForRemoval] = useState<AMDSummaryOutput | ''>('')
  const navigate = useNavigate()
  const handleNewAMD = () => {
    navigate('/setup')
  }

  const handleRemoveAMD = () => {
    setFetchingAMDsSummary(true)
    setModalOpen(false)
    API.UnclaimAMDByIdPhrase<APIResponse<{}>>((selectedAMDForRemoval as AMDSummaryOutput).idPhrase)
      .then((response) => {
        console.log('response is ', response)
        if (response.status === 200) {
          setAMDSummaryOutputs(
            AMDSummaryOutputs.filter(amdSummaryOutput => amdSummaryOutput.idPhrase !== (selectedAMDForRemoval as AMDSummaryOutput).idPhrase)
          )
          setFetchingAMDsSummary(false)
        }
      })
  }

  const handleDownloadAllData = () => {
    setFetchingCSV(true)
    API.AMDCSVExport()
      .then((response) => {
        console.log('response csv is ', response)
        // const blob = new Blob([response.data], {type: 'text/csv'})
        const link = document.createElement('a')
        link.href = response.data.Location
        link.target = '_blank'
        // link.href = window.URL.createObjectURL(blob)
        link.download = `measurements-${new Date()}`
        link.click()
        window.URL.revokeObjectURL(link.href)
        link.remove()
        setFetchingCSV(false)
      })
  }

  const handleDeleteIconButton = (targetAMD: AMDSummaryOutput) => {
    setModalOpen(true)
    setSelectedAMDForRemoval(targetAMD)
  }

  useEffect(() => {
    setFetchingAMDsSummary(true)
    API.GetMyAMDsSummary<APIResponse<ArrayOutput<AMDSummaryOutput>>>()
      .then((response) => {
        setAMDSummaryOutputs(() => [...response.data.data.items])
        setFetchingAMDsSummary(false)
      })
  }, [])
  return (
    <>
    <ContentTemplate title='AMDs'>
      <Box component='div' sx={{ marginY: '30px', marginX: '16px'}}>
        <StyledButton onClick={handleNewAMD} variant='outlined' sx={{ borderRadius: '25px'}}>
          New AMD +
        </StyledButton>
        <InfoActionCard sx={{ marginY: '30px'}}>
          { fetchingAMDsSummary ?
          <CircularProgress color='inherit' />
            :
          <StyledTableContainer>
            <Table>
              <TableHead>
                <TableRow key='IDs'>
                  <HeadTableCell key='id-head'>ID</HeadTableCell>
                  {['Deployment', 'Location', 'Measurement Spec', 'Radio Region', 'Actions'].map((item) => (
                    <HeadTableCell key={`${item}-head`} align='right'>{item}</HeadTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                { AMDSummaryOutputs?.map((amdSummaryOutput) => {
                  const {idPhrase, region, deploymentSelection: {name, locationAddress}, configurationSelection: {label}} = amdSummaryOutput
                  return (
                  <TableRow hover key={`${idPhrase}-body-row`}>
                    <BodyTableCell key={`${idPhrase}-body-cell`}>{idPhrase}</BodyTableCell>
                    {[name, locationAddress, label, region].map((item) => (
                      <BodyTableCell key={`${item}-body-cell`} align='right'>{item}</BodyTableCell>
                    ))}
                    <BodyTableCell align='right'>
                      <IconButton onClick={() => handleDeleteIconButton(amdSummaryOutput)}>
                        <DeleteIcon />
                      </IconButton>
                    </BodyTableCell>
                  </TableRow>
                )})}
              </TableBody>
            </Table>
          </StyledTableContainer>
          }
        </InfoActionCard>
        <BlackButton onClick={handleDownloadAllData} disabled={fetchingCSV} variant='contained' sx={{ width: '29%' }} type='submit'>
          { fetchingCSV 
            ? <CircularProgress color='inherit' sx={{ padding: '4px' }} />
            : <Typography variant='button'> Download All Data <DownloadIcon sx={{ position: 'absolute', right: '0px', top: '0px', marginX: '20px', height: '100%'}} /></Typography>
          }
          
        </BlackButton>
      </Box>
    </ContentTemplate>
    <ModalConfirm
      heading='Confirm Remove'
      prompt='Are you sure you would like to remove this AMD from your account?'
      primaryText='Remove'
      secondaryText='Cancel'
      handlePrimary={handleRemoveAMD}
      handleSecondary={() => setModalOpen(false)}
      handleClose={() => setModalOpen(false)}
      open={modalOpen}
    />
    </>
  )
}

const BodyTableCell = styled(TableCell)({
  borderBottom: '2px solid rgba(30, 30, 30, 0.5)'
})

const HeadTableCell = styled(BodyTableCell)(({
  fontWeight: 'bold',
}))

const StyledTableContainer = styled(TableContainer)(({theme}) => ({
  backgroundColor: 'transparent'
}))

const BlackButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  height: '40px',
  backgroundColor: theme.palette.background.default,
  color: 'white',
  ':hover': {
    background: alpha(theme.palette.background.default, 0.8),
  }
}))
