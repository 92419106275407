import { alpha, Box, CircularProgress, List, ListItem, ListItemText, ListProps, styled } from '@mui/material'
import { FC, PropsWithChildren, useEffect, useState } from 'react'

interface ListWithCircularProgressProps extends ListProps {
  loading: boolean
  itemName: string
  collectionName: string
  data: []
  liHeightRef: React.RefObject<HTMLLIElement>
}

export const ListWithCircularProgress: FC<PropsWithChildren<ListWithCircularProgressProps>> = ({ children, ...props }) => {
  const { loading, data, itemName, collectionName, liHeightRef } = props

  
  const [listItemHeight, setListItemHeight] = useState(20)
  useEffect(() => {
    const listItemHeight = liHeightRef.current?.getBoundingClientRect().height ?? 20
    setListItemHeight(listItemHeight)
  }, [liHeightRef])
  
  return (
    <StyledList 
      sx={{
        maxHeight: listItemHeight * 3,
        backgroundColor: alpha('#fff', data?.length === 0 || loading ? 0.5 : 1),
        overflow: data?.length === 0 ? 'visible' : 'auto'
      }}>
      { data?.length === 0 && !loading &&
        <ListItem ref={liHeightRef}>
          <ListItemText>There are no {itemName}s in this {collectionName}</ListItemText>
        </ListItem>
      }
      { loading ?
      <>
        <ListItem ref={liHeightRef}>
          <ListItemText>Fetching {itemName}s</ListItemText>
        </ListItem>
        <Box sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '0px'}}>
          <CircularProgress color='inherit' size='20px' sx={{ position: 'relative' }} />
        </Box>
      </>
      : children
      }
    </StyledList>
  )
}

const StyledList = styled(List)(({
  width: '100%',
  position: 'relative',
  borderRadius: '10px',
  border: '1px solid #CDC3B9',
}))