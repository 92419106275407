import API from '../../../api'
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTitle } from '../Onboarding'
import { CircularProgress, Divider, Typography, Box } from '@mui/material'

export const Activate: FunctionComponent = () => {
  const [loggingIn, setLoggingIn] = useState(true)
  const navigate = useNavigate()

  const { activationToken } = useParams()

  const { setTitle } = useTitle()
  useEffect(() => {
    setTitle('Activation')
    if (activationToken !== undefined)
      API.ActivateEmail({ token: activationToken })
        .then((response) => {
          navigate('overview')
        })
        .catch((error) => {
          setLoggingIn(false)
        })
  })

  return (
    <Box component='div' sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Divider variant='fullWidth' sx={{ width: '100%', marginTop: '30px' }} />
        <Box component='div' sx={{ margin: "30px" }}>
          { loggingIn ? 
            <CircularProgress color='inherit' />
          :
            <Typography variant="subtitle2">
              We could not verify your email.
            </Typography> 
          }
        </Box>
    </Box>
  )

}