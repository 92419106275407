import { ModalContent } from '../../../../components/Modal/ModalContent'
import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CardParagraph } from '../../../../components/Text/CardParagraph'
import { Box, Divider, FormControl, Slider, styled } from '@mui/material'
import { StyledFormLabel } from '../../../../components/Form/StyledFormLabel'
import { InlineAsterisk } from '../../../../components/Text/InlineAsterisk'
import { Mark } from '@mui/base'
import { FormTextArea, FormTextField } from '../../../../components/TextFields/FormText'
import { CreateMeasurementSpecInput } from '@/models/MeasurementSpec/input/createMeasurementSpecInput'
import API from '../../../../api'
import { APIResponse } from '@/types/API/response.output'
import { MeasurementSpecOutput } from '@/models/MeasurementSpec/output/measurementSpec.output'

interface MeasurementSpecStepModalProps {
  measurementSpecOutputsState: [MeasurementSpecOutput[], React.Dispatch<React.SetStateAction<MeasurementSpecOutput[]>>]
  modalOpenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  setFetchingMeasurementSpecs: React.Dispatch<React.SetStateAction<boolean>>
  setTargetMeasurementSpec: React.Dispatch<React.SetStateAction<MeasurementSpecOutput | ''>>
}

export const MeasurementSpecStepModal: FC<MeasurementSpecStepModalProps> = (props) => {
  const { 
    measurementSpecOutputsState: [measurementSpecOutputs, setMeasurementSpecOutputs],
    modalOpenState: [modalOpen, setModalOpen],
    setFetchingMeasurementSpecs,
    setTargetMeasurementSpec,
  } = props

  const { register, handleSubmit, setValue, control } = useForm<CreateMeasurementSpecInput>({ defaultValues: {
    hourlyInterval: 1,
    hourlyOffset: 0,
    minuteWiseInterval: 60,
    minuteWiseOffset: 0,
    label: '',
    notes: '',
  }})

  const [hourlyOffsetMarks, setHourlyOffsetMarks] = useState<{ value: number }[]>(
    Array.from({ length: 24 }, (_, i) => i).map(value => ({ value }))
  )
  const [hourlyOffsetMax, setHourlyOffsetMax] = useState<number>(0)

  const [minuteWiseOffsetMarks, setMinuteWiseOffsetMarks] = useState<{ value: number }[]>(
    Array.from({ length: 60 }, (_, i) => i).map(value => ({ value }))
  )
  const [minuteWiseOffsetMax, setMinuteWiseOffsetMax] = useState<number>(0)

  const handleCancelModal = () => {
    setModalOpen(false)
  }

  const handleCreateMeasurementSpecSubmit = (data: CreateMeasurementSpecInput) => {
    setFetchingMeasurementSpecs(true)
    API.CreateMeasurementSpec<APIResponse<MeasurementSpecOutput>>(data)
      .then((response) => {
        const newMeasurementSpecOutput = response.data.data
        setMeasurementSpecOutputs([...measurementSpecOutputs, newMeasurementSpecOutput])
        setTargetMeasurementSpec(newMeasurementSpecOutput)
        setFetchingMeasurementSpecs(false)
      })
    setModalOpen(false)
  }

  const hourlyIntervalValues = [1, 2, 3, 4, 6, 8, 12, 24]
  const hourlyIntervalMarks = hourlyIntervalValues.map((value) => ({ value, label: `${value}h` }))

  const minuteWiseIntervalValues = [1, 2, 3, 4, 5, 6, 10, 12, 15, 30, 60]
  const labelsVisible = [1, 5, 10, 15, 30, 60]
  const minuteWiseIntervalMarks = minuteWiseIntervalValues.map((value) => {
    const mark: Mark = { value }
    if (labelsVisible.includes(value)) mark.label = `${value}m`
    return mark
  })

  const valueLabelFormatHourly = (value: number) => {
    return `${value}h`
  }
  const valueLabelFormatMinuteWise = (value: number) => {
    return `${value}m`
  }

  const handleHourlyIntervalChange = (event: Event, value: number | number[]) => {
    if (typeof value === 'number') {
      setHourlyOffsetMax(value - 1)
      setHourlyOffsetMarks(
        Array.from({ length: value }, (_, i) => i).map(value => ({ value }))
      )
      setValue('hourlyOffset', 0)
    }
  }

  const handleMinuteWiseIntervalChange = (event: Event, value: number | number[]) => {
    if (typeof value === 'number') {
      setMinuteWiseOffsetMax(value - 1)
      setMinuteWiseOffsetMarks(
        Array.from({ length: value }, (_, i) => i).map(value => ({ value }))
      )
      setValue('minuteWiseOffset', 0)
    }
  }

  return (
    <ModalContent
      heading='Create New Measurement Specification'
      primaryText='Continue'
      secondaryText='Cancel'
      handleSecondary={handleCancelModal}
      open={modalOpen}
      handleClose={handleCancelModal}
      form={handleSubmit(handleCreateMeasurementSpecSubmit)}
    >
      <FormControl fullWidth>
        <Box component='div' sx={{ marginY: '10px' }}>
          <CardParagraph sx={{ marginTop: '16px' }}>
            Please select the hourly interval and its offset below
          </CardParagraph>
          <StyledHourlyIntervalContainer>
            <Box component='div' sx={{ gridArea: 'interval' }}>
              <StyledFormLabel id='hourly-interval-slider-label'>Hourly Interval<InlineAsterisk /></StyledFormLabel>
              <Box component='div' sx={{ width: '100%', marginLeft: '10px' }}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name='hourlyInterval'
                  render={({ field }) => (
                    <StyledSlider
                      aria-label='Hourly Interval'
                      defaultValue={1}
                      step={null}
                      valueLabelDisplay='auto'
                      valueLabelFormat={valueLabelFormatHourly}
                      getAriaValueText={valueLabelFormatHourly}
                      marks={hourlyIntervalMarks}
                      min={1}
                      max={24}
                      onChange={(e, v) => {
                        handleHourlyIntervalChange(e, v)
                        field.onChange(e)
                      }}
                      value={field.value}
                    />
                    )
                  }
                />
              </Box>
            </Box>
            <Box component='div' sx={{ gridArea: 'offset', marginLeft: '10px' }}>
              <StyledFormLabel id='hourly-offset-slider-label'>Hourly Offset<InlineAsterisk /></StyledFormLabel>
              <Box component='div' sx={{ width: '100%' }}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name='hourlyOffset'
                  render={({ field }) => (
                    <StyledSlider
                      aria-label='Hourly Offset'
                      defaultValue={1}
                      step={null}
                      valueLabelDisplay='auto'
                      valueLabelFormat={valueLabelFormatHourly}
                      getAriaValueText={valueLabelFormatHourly}
                      marks={hourlyOffsetMarks}
                      min={0}
                      max={hourlyOffsetMax}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
              </Box>
            </Box>
            {/* <Box component='div' sx={{ gridArea: 'render' }}>
              <CardParagraph sx={{ marginTop: '16px' }}>The Measurements will be triggered every day during the following hours</CardParagraph>
            </Box> */}
          </StyledHourlyIntervalContainer>
        </Box>
        <Divider />
        <Box component='div' sx={{ marginY: '10px' }}>
          <CardParagraph sx={{ marginTop: '16px' }}>
          Please select the minute-wise interval and its offset below - the Measurements will be triggered every minute shown on the clock during the selected hours
          </CardParagraph>
          <StyledMinuteWiseIntervalContainer>
            <Box component='div' sx={{ gridArea: 'interval', marginY: '10px' }}>
              <StyledFormLabel id='minute-wise-interval-slider-label'>Minute-wise Interval<InlineAsterisk /></StyledFormLabel>
              <Box component='div' sx={{ width: '100%', marginLeft: '10px' }}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name='minuteWiseInterval'
                  render={({ field }) => (
                    <StyledSlider
                      aria-label='Minute-Wise Interval'
                      defaultValue={60}
                      step={null}
                      valueLabelDisplay='auto'
                      valueLabelFormat={valueLabelFormatMinuteWise}
                      getAriaValueText={valueLabelFormatMinuteWise}
                      marks={minuteWiseIntervalMarks}
                      min={1}
                      max={60}
                      onChange={(e, v) => {
                        handleMinuteWiseIntervalChange(e, v)
                        field.onChange(e)
                      }}
                      value={field.value}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box component='div' sx={{ gridArea: 'offset', marginY: '10px'  }}>
              <StyledFormLabel id='minute-wise-offset-slider-label'>Minute-wise Offset<InlineAsterisk /></StyledFormLabel>
              <Box component='div' sx={{ width: '100%', marginLeft: '10px' }}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name='minuteWiseOffset'
                  render={({ field }) => (
                    <StyledSlider
                      aria-label='Minute-Wise Offset'
                      defaultValue={1}
                      step={null}
                      valueLabelDisplay='auto'
                      valueLabelFormat={valueLabelFormatMinuteWise}
                      getAriaValueText={valueLabelFormatMinuteWise}
                      marks={minuteWiseOffsetMarks}
                      min={0}
                      max={minuteWiseOffsetMax}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
              </Box>
            </Box>
          </StyledMinuteWiseIntervalContainer>
        </Box>
        <Divider />
        <Box component='div' sx={{ marginTop: '20px' }}>
          <Box component='div' sx={{ width: '48%' }}>
            <StyledFormLabel id='measurement-spec-setup-label-label'>Spec Label<InlineAsterisk /></StyledFormLabel>
            <FormTextField sx={{ marginTop: '6px', width: '100%' }} placeholder='Label' margin='normal' {...register('label', { required: true })} />
          </Box>
          <Box component='div' sx={{ gridArea: 'notes' }}>
            <StyledFormLabel id='measurement-spec-setup-notes-label'>Notes</StyledFormLabel>
            <FormTextArea multiline rows={2} sx={{ marginTop: '6px', width: '100%' }} placeholder='Enter any notes related to the specification' margin='normal' {...register('notes')} />
          </Box>
        </Box>
      </FormControl>
    </ModalContent>
  )
}

const StyledHourlyIntervalContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas:
    `'interval offset'
     'render render'`,
  gridTemplateRows: 'auto auto',
  gridTemplateColumns: '63% 31%',
  columnGap: '6%',
}))

const StyledMinuteWiseIntervalContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas:
    `'interval render'
     'offset render'`,
  gridTemplateRows: 'auto auto',
  gridTemplateColumns: '63% 31%',
  columnGap: '6%',
}))

const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-mark': {
    height: '8px',
  },
  '& .MuiSlider-markActive': {
    height: '2px',
  }
}))
