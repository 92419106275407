import { InfoActionCard } from '../../../../components/Cards/InfoActionCard'
import React, { createRef, FC, useEffect, useState } from 'react'
import { SectionHeading } from '../../../../components/Text/SectionHeading'
import { CardParagraph } from '../../../../components/Text/CardParagraph'
import { FormControlSingleRow } from '../../../../components/Form/FormControlSingleRow'
import { Box, Divider, List, ListItem, ListItemText } from '@mui/material'
import { StyledFormLabel } from '../../../../components/Form/StyledFormLabel'
import { InlineAsterisk } from '../../../../components/Text/InlineAsterisk'
import { StyledButton } from '../../../../components/Buttons/StyledButton'
import { GatewayOutput } from '@/models/Gateway/output/gateway.output'

interface GatewayStepProps {
  setContinueEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

export const GatewaysStep: FC<GatewayStepProps> = (props) => {
  const { setContinueEnabled } = props

  const [gatewayOutputs, setGatewayOutputs] = useState<Array<GatewayOutput>>([{
    _id: 'RAK ID',
    accountId: 'RAK Wireless accountId',
    name: 'RAK Wireless',
    description: 'RAK Wireless description',
    location: 'fake location',
    deploymentId: 'fake deployment Id',
    EUI: '',
    region: 'EU868',
  }])

  let ref: React.RefObject<HTMLLIElement> = createRef()
  
  const [listItemHeight, setListItemHeight] = useState(20)
  useEffect(() => {
    const listItemHeight = ref.current?.getBoundingClientRect().height ?? 20
    setListItemHeight(listItemHeight)
  }, [ref])

  useEffect(() => {
    gatewayOutputs.length !== 0 ? setContinueEnabled(true) : setContinueEnabled(false)
  }, [gatewayOutputs, setContinueEnabled])

  return (
    <InfoActionCard>
      <SectionHeading size='h6'>Gateways</SectionHeading>
      <CardParagraph sx={{ marginTop: '16px' }}>At least one <strong>Gateway</strong> must serve your chosen <strong>Deployment</strong>. AMDs transmit all data through <strong>Gateways</strong>.</CardParagraph>
      <FormControlSingleRow>
        <Box component='div' sx={{ gridArea: 'left' }}>
          <StyledFormLabel id='gateways-view-label'>Gateways servicing this Deployment<InlineAsterisk /></StyledFormLabel>
          <List
            sx={{
              maxHeight: listItemHeight * 3,
              width: '100%',
              backgroundColor: 'white',
              position: 'relative',
              overflow: 'auto',
              borderRadius: '10px',
              border: '1px solid #CDC3B9',
            }}
          >
            { gatewayOutputs.map((gateway, ix) => (
              <Box component='div' key={`list-item-gateway-${gateway._id}`}>
                <ListItem ref={ref}>
                  <ListItemText primary={gateway.name} />
                </ListItem>
                { ix !== gatewayOutputs.length - 1 && <Divider variant='middle' component='li' /> }
              </Box>
            ))
            }
          </List>
        </Box>
        {/* TODO: The button is disabled for the MVP */}
        <StyledButton disabled variant='outlined' sx={{ gridArea: 'right', position: 'absolute', width: '100%', bottom: '0px' }}>
          New Gateway +
        </StyledButton>
      </FormControlSingleRow>
    </InfoActionCard>
  )
}