import API from '../../../api'
import { FunctionComponent, useState } from 'react'

import { useForm } from 'react-hook-form'
import { ResetPasswordInput, ResetPasswordOutput } from '@/types/Onboarding'
import { Box, Button } from '@mui/material'
import { FormTextField } from '../../../components/TextFields/FormText'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router'

const log = console.log

interface ResetPasswordFormInput extends ResetPasswordInput {
  repeatPassword: string
}

export const ResetPasswordExec: FunctionComponent = () => {
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm<ResetPasswordFormInput>()
  const [, setFormError] = useState(false)
  const { resetToken } = useParams()

  const onSubmit = (data: ResetPasswordFormInput) => {
    const {repeatPassword, ...resetPasswordInput } = data
    if (repeatPassword !== resetPasswordInput.password) {
      setFormError(true)
      return
    } // guard 1
    resetPasswordInput.token = resetToken!
    API.ResetPassword<ResetPasswordOutput>(resetPasswordInput)
      .then((response) => {
        setFormError(false)
        navigate('/overview')
      })
      .catch((error) => {
        setFormError(true)
        log("Sign In Error: ", error)
      })
  }
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ display: "flex", flexDirection: "column", marginBottom: "28px"}}>
      <FormTextField placeholder="New Password" margin="normal" type="password" {...register('password', { required: true })} />
      <FormTextField placeholder="Repeat Password" margin="normal" type="password" {...register('repeatPassword', { required: true })} />
      <Button variant="contained" type="submit" sx={{ marginTop: "16px", marginBottom: "8px", height: "40px" }}>Continue</Button>
    </Box>
  )
}
