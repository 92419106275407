import { configureStore } from '@reduxjs/toolkit'
import userReducer from './User/user.slice'
import thunkMiddleware from 'redux-thunk'

export const store = configureStore({
    reducer: {
        user: userReducer,
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware()
            .concat(thunkMiddleware)
    ,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch