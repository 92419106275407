import { alpha, Button, styled } from '@mui/material'

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  height: '40px',
  border: `2px solid ${theme.palette.primary.main}`,
  background: alpha(theme.palette.primary.main, 0.1),
  ':hover': {
    border: `2px solid ${theme.palette.primary.main}`,
    background: alpha(theme.palette.primary.main, 0.2),
  }
}))