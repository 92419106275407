import { alpha, Card, styled } from '@mui/material'

export const InfoActionCard = styled(Card)(({ theme }) => ({
  padding: '24px',
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '80%',
  },
  [theme.breakpoints.up('xxl')]: {
    width: '70%',
  },
  backgroundColor: alpha(theme.card.bg, 0.5),
  boxShadow: theme.boxShadow,
}))