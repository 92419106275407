import { Box, Divider, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { FC, PropsWithChildren } from 'react'

interface SectionHeadingProps {
  size: Variant
}

export const SectionHeading: FC<PropsWithChildren<SectionHeadingProps>> = ({ children, ...props }) => {
  const { size } = props
  return (
    <Box component='div'>
      <Typography variant={size} component='div'>{children}</Typography>
      <Divider />
    </Box>
  )
}