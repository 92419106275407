import { LocalStorageDriver } from '../../../state/localStorage'
import { SigninInput, SigninOutput } from '@/types/Onboarding'
import { AnyAction, createAsyncThunk, createSlice, ThunkAction } from '@reduxjs/toolkit'
import API from '../../../api'
import { RootState } from '../store'

export enum HydrationStatus {
    EMPTY = 'empty',
    WAITING = 'waiting',
    LOADED = 'loaded',
    FAILED = 'failed'
}

export interface UserState {
    signedIn: boolean
    status: HydrationStatus
    dismissedWelcomeModal: boolean
    name?: string
    email?: string
    loginToken?: string
}

const initialState: UserState = {
    signedIn: true,
    status: HydrationStatus.EMPTY,
    dismissedWelcomeModal: false
}

export const signIn = createAsyncThunk(
    'user/signIn',
    async (data: SigninInput, thunkAPI) => {
        const response = await API.SignIn<SigninOutput>(data)
        return response.data
    }
)

export const signOutThunk = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    dispatch(userSlice.actions.signOut())
    const user = getState().user
    if (!user.signedIn) LocalStorageDriver.clearAccountData()
  }
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loadFromStorage: (state) => {
            const accountData = LocalStorageDriver.getAccountData()
            if (accountData === null) return
            const { name, email, loginToken } = accountData
            state.name = name
            state.email = email
            state.loginToken = loginToken
            state.signedIn = true
            state.status = HydrationStatus.LOADED
        },
        signOut: (state) => {
            state.signedIn = false
            state.status = HydrationStatus.EMPTY
            delete state.email
            delete state.name
            delete state.loginToken
        },
        dismissWelcomeModal: (state) => {
          state.dismissedWelcomeModal = true
        }
    },
    extraReducers: (builder) => {
        builder.addCase(signIn.pending, (state, action) => {
            state.status = HydrationStatus.WAITING
        })
        builder.addCase(signIn.fulfilled, (state, action) => {
            const { name, loginToken, email } = action.payload.data
            state.name = name
            state.email = email
            state.loginToken = loginToken
            state.signedIn = true
            state.status = HydrationStatus.LOADED
        })
        builder.addCase(signIn.rejected, (state, action) => {
            state.status = HydrationStatus.FAILED
        })
    }
})

export const { signOut, loadFromStorage, dismissWelcomeModal } = userSlice.actions
export default userSlice.reducer
