import './App.css'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { Signup } from './pages/Onboarding/Signup/Signup'
import { FunctionComponent, useEffect } from 'react'
import { CssBaseline, LinkProps, ThemeProvider } from '@mui/material'
import { alpha, createTheme } from '@mui/material/styles'
import { Onboarding } from './pages/Onboarding/Onboarding'
import { Activate } from './pages/Onboarding/Activate/Activate'
import { Signin } from './pages/Onboarding/Login/Signin'
import { ResetPassword } from './pages/Onboarding/ResetPassword/ResetPassword'
import { useTypedDispatch, useTypedSelector } from './state/hooks'
import { loadFromStorage, UserState } from './state/store/User/user.slice'
import { Overview } from './pages/Overview/Overview'
import { Dashboard } from './pages/Dashboard/Dashboard'
import { Setup } from './pages/Setup/Setup'
import { AMDs } from './pages/AMDs/AMDs'
import { Gateways } from './pages/Gateways/Gateways'
import { Deployments } from './pages/Deployments/Deployments'
import { LinkBehavior } from './components/Links/LinkBehaviour'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    xxl: true
  }
  interface Theme {
    dashboard: {
      bg: string
    }
    sidebar: {
      bg: string
      selected: string
      selectedHover: string
    }
    content: {
      bg: string
      card: string
    }
    card: {
      bg: string
    }
    boxShadow: string
  }

  interface ThemeOptions {
    dashboard?: {
      bg?: string
    }
    sidebar?: {
      bg?: string
      selected?: string
      selectedHover?: string
    }
    content?: {
      bg?: string
      card?: string
    },
    card?: {
      bg? : string
    },
    boxShadow?: string
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1800,
    }
  },
  dashboard: {
    bg: '#fff'
  },
  sidebar: {
    bg: alpha('#1E1E1E', 0.05),
    selected: alpha('#1E1E1E', 0.25),
    selectedHover: alpha('#1E1E1E', 0.5)
  },
  content: {
    bg: alpha('#F5EBE1', 0.25),
    card: alpha('#F5EBE1', 0.5),
  },
  card: {
    bg: '#F5EBE1',
  },
  boxShadow: `0px 4px 8px ${alpha('#1E1E1E', 0.25)}`,
  palette: {
    background: {
      default: '#1E1E1E',
    },
    primary: {
      main: '#1E5FE1',
      light: '#E8EFFF'
    },
    secondary: {
      main: '#B9EBC3'
    },
    divider: 'rgba(30, 30, 30, 0.3)',
  },
  typography: {
    button: {
      textTransform: 'none',
      fontSize: 18,
      fontWeight: 'bold'
    }
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          backgroundColor: "#fff"
        },
        notchedOutline: {
          borderColor: '#CDC3B9',
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        }
      }
    }
  }
})

type ProtectedRoutesProps = {
  user: UserState
  redirectPath: string
}
export const ProtectedRoutes: FunctionComponent<ProtectedRoutesProps> = (props) => {
  return props.user.signedIn ? (<Outlet />) : (<Navigate to={props.redirectPath} replace />)
}

export const AppRouting: FunctionComponent = () => {
  const user = useTypedSelector(state => state.user)
  const dispatch = useTypedDispatch()

  useEffect(() => {
    dispatch(loadFromStorage())
  })

  return (
    <Routes>
      <Route element={<ProtectedRoutes user={user} redirectPath='signin' />} >
        <Route element={<Dashboard />}>
          <Route path='overview' element={<Overview />} />
          <Route path='AMDs' element={<AMDs />} />
          <Route path='deployments' element={<Deployments />} />
          <Route path='gateways' element={<Gateways />} />
          <Route path='setup' element={<Setup />} />
        </Route>
      </Route>
      <Route path='signin' element={
        user.signedIn
          ? <Navigate to='overview' replace />
          : <Onboarding />}
      >
        <Route path='' element={<Signin />} />
        <Route path='resetPassword' element={<ResetPassword />} />
        <Route path='resetPassword/:resetToken' element={<ResetPassword />} />
      </Route>
      <Route path='signup' element={<Onboarding />}>
        <Route path='' element={<Signup />} />
        <Route path='activate/:activationToken' element={<Activate />} />
      </Route>
      <Route path='*' element={
        user.signedIn
          ? <Navigate to='/overview' replace />
          : <Navigate to='/signin' replace />}
      />
    </Routes>
  )
}

export const App: FunctionComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRouting />
    </ThemeProvider>
  )
}
