import { Box, Icon, styled, Typography, Button } from '@mui/material'
import { FC, forwardRef } from 'react'
import amdRender from '../../images/AMD/amd-render-iso.png'
import bluePlus from '../../images/Icons/plus-blue.png'

import { 
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'

export const AddActionCard: FC = () => {
  return (
    <AddAction LinkComponent={ComposedLink} href='/setup' >
      <Typography variant='h5' sx={{ gridArea: 'top', textAlign: 'center', fontWeight: 'bold' }}>AMD</Typography>
      <Box sx={{ gridArea: 'mid', display: 'flex', height: '90px', width: '90px', border: '2px solid #CDC3B9', borderRadius: '8px', justifySelf: 'center' }}>
        <Icon component='img' src={bluePlus} style={{ margin: 'auto', width: '50%', height: '50%' }} />
      </Box>
      <img style={{ position: 'absolute', right: '-10%', bottom: '-20%', opacity: '10%' }} alt='Isometric render of the AMD' src={amdRender} />
    </AddAction>
  )
}

const ComposedLink = forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
    const { href, ...others } = props
    return (
      <RouterLink ref={ref} to={href} {...others} />
    )
  }
)

const AddAction = styled(Button)(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas:
    `'top top top'
     'mid mid mid'
     'bot bot bot'`,
  gridTemplateRows: '33% 33% 33%',
  gridTemplateColumns: '33% 33% 33%',
  position: 'relative',
  overflow: 'clip',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '250px',
  height: '250px',
  backgroundColor: theme.content.card,
  borderRadius: '4px',
  boxShadow: theme.boxShadow,
  color: '#1E1E1E',
  ':hover': {
    backgroundColor: theme.content.card,
  }
}))