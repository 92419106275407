import API from '../../../api'
import { FunctionComponent, useState } from 'react'

import { useForm } from 'react-hook-form'
import { ResetPasswordRequestInput, ResetPasswordRequestOutput } from '@/types/Onboarding'
import { Box, Button, Typography } from '@mui/material'
import { FormTextField } from '../../../components/TextFields/FormText'

const log = console.log

export const ResetPasswordRequest: FunctionComponent = () => {
  const { register, handleSubmit } = useForm<ResetPasswordRequestInput>()
  const [, setFormError] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [response, setResponse] = useState('')

  const onSubmit = (data: ResetPasswordRequestInput) => {
    API.RequestPasswordReset<ResetPasswordRequestOutput>(data)
      .then((response) => {
        setFormError(false)
        setResponse(response.data.data.email)
        setFormSubmitted(true)
        // navigate('/overview')
      })
      .catch((error) => {
        setFormError(true)
        log("Sign In Error: ", error)
      })
  }
  return (
    <Box component='div' sx={{ width: '100%'}}>
    { !formSubmitted ?
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ display: "flex", flexDirection: "column", marginBottom: "28px"}}>
        <Typography variant="subtitle1" sx={{ marginTop: "16px", marginBottom: "8px", marginX: "16px", color: "var(--grey-color)"}}>
          Send a password reset link to a registered email
        </Typography>
        <FormTextField placeholder="Email" margin="normal" {...register("email", { required: true })} />
        <Button variant="contained" type="submit" sx={{ marginTop: "16px", marginBottom: "8px", height: "40px" }}>Continue</Button>
      </Box>
      :
      <Typography variant="subtitle1" sx={{ marginTop: "16px", marginBottom: "8px", marginX: "16px"}}>
        Password Reset Link sent to {response}
      </Typography>
    }
    </Box>
  )
}
