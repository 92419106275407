import { Box, styled } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

export const Content: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  )
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  backgroundColor: theme.content.bg,
  gridArea: 'content',
}))