import { FunctionComponent, useEffect } from "react"

import { Button, Divider, Typography, Box } from "@mui/material"
import { useTitle } from '../Onboarding'
import { Link as RouterLink } from 'react-router-dom'
import { useParams } from 'react-router'
import { ResetPasswordExec } from './ResetPasswordExec'
import { ResetPasswordRequest } from './ResetPasswordRequest'

export const ResetPassword: FunctionComponent = () => {

  const { resetToken } = useParams()
  

  const { setTitle } = useTitle()
  useEffect(() => {
    setTitle('Reset Password')
  })
  
  return (
    <Box component='div' sx={{ width: '100%'}}>
      {
        resetToken !== undefined
        ?
        <ResetPasswordExec />
        :
        <ResetPasswordRequest />
      }
      <Divider variant="fullWidth" sx={{ width: "100%" }} />
      <Box component="div" sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", marginTop: "30px" }}>
        <Typography variant="subtitle2">
          Don't have an account?
        </Typography>
        <Button 
          component={RouterLink}
          to='/signup'
          variant='contained'
          sx={{
            marginTop: "16px",
            marginBottom: "8px",
            width: "100%", 
            height: "40px", 
            color: "primary.main",
            backgroundColor: "primary.light",
            border: 1,
            borderColor: "primary.main",
            '&:hover': {
              backgroundColor: "primary.light",
            }
          }}>Sign Up</Button>
      </Box>
    </Box>
  )
}
