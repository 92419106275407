import { ModalContent } from '../../../../components/Modal/ModalContent'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { CardParagraph } from '../../../../components/Text/CardParagraph'
import { alpha, Box, Button, FormControl, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledFormLabel } from '../../../../components/Form/StyledFormLabel'
import { FormTextField } from '../../../../components/TextFields/FormText'
import { InlineAsterisk } from '../../../../components/Text/InlineAsterisk'

interface AMDsStepModalProps {
  modalOpenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

export const AMDsStepModal: FC<AMDsStepModalProps> = (props) => {
  const { modalOpenState: [modalOpen, setModalOpen] } = props

  const handleCancelModal = () => {
    setModalOpen(false)
  }
  const { register, handleSubmit } = useForm()
  const handleClaimAMDs = () => {

  }

  return (
    <ModalContent
      heading='Add one or more AMDs'
      primaryText='Continue'
      secondaryText='Cancel'
      handleSecondary={handleCancelModal}
      open={modalOpen}
      handleClose={handleCancelModal}
      form={handleSubmit(handleClaimAMDs)}
    >
      <CardParagraph sx={{ marginY: '10px' }}>
        Add AMDs by typing individual IDs.
      </CardParagraph>
      <FormControl fullWidth>
        <Box component='div' sx={{ display: 'flex', flexDirection: 'column' }}>
          <StyledFormLabel id='amd-setup-id-label'>ID<InlineAsterisk /></StyledFormLabel>
          <Box component='div' sx={{ flexDirection: 'row', width: '48%' }}>
            <FormTextField sx={{ marginTop: '6px', width: '67%' }} placeholder='fezzy-fizzy-fuzzy' margin='normal' {...register('name', { required: true })} />
            <StyledButton variant='contained' sx={{ marginTop: '6px', width: '29%', marginLeft: '4%' }}>Retrieve +</StyledButton>
          </Box>
        </Box>
        <Box component='div'>
          <StyledFormLabel id='deployment-setup-siteaddr-label'>AMDs to deploy<InlineAsterisk /></StyledFormLabel>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>drink-pinky-chill</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </FormControl>
    </ModalContent>
  )
}

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  height: '40px',
  backgroundColor: theme.palette.background.default,
  color: 'white',
  ':hover': {
    background: alpha(theme.palette.background.default, 0.8),
  }
}))