import { ReactComponent as ZoneosLogo } from '../../images/zoneos-logo.svg'
import { OnboardingCard } from '../../components/Cards/OnboardingCard'
import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react'
import { CardContent, SvgIcon, Typography } from '@mui/material'
import { Outlet, useOutletContext } from 'react-router'

export const Onboarding: FunctionComponent = () => {
  const [title, setTitle] = useState("Onboarding")

  return (
    <OnboardingCard>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {
          process.env.NODE_ENV === "production" &&
          <>Our Platform is under construction</>
        }
        <SvgIcon component={ZoneosLogo} sx={{ margin: '20px' }} />
        <Typography variant='subtitle2' sx={{ color: 'var(--grey-color)' }}>
          Monitoring Systems
        </Typography>
        <Typography variant='h5'>
          {title}
        </Typography>
        <Outlet context={{setTitle}} />
      </CardContent>
    </OnboardingCard>
  )
}

interface OnboardingOutletContext {
  setTitle: Dispatch<SetStateAction<string>>
}

export const useTitle = () => {
  return useOutletContext<OnboardingOutletContext>()
}
