import { ClaimAMDInput } from '@/models/AMD/input/claimAMD.input'
import { CreateDeploymentInput } from '@/models/Deployment/input/createDeployment.input'
import { CreateMeasurementSpecInput } from '@/models/MeasurementSpec/input/createMeasurementSpecInput'
import { signOutThunk } from '../state/store/User/user.slice'
import { ActivateEmailInput, SignupInput, SigninInput, ResetPasswordInput, ResetPasswordRequestInput } from '@/types/Onboarding'
import axios from 'axios'
import { store } from '../state/store/store'

export default class API {
  public static AUTH_PATH = process.env.REACT_APP_AUTH_PATH
  public static ACCOUNT_RESOURCE_PATH = process.env.REACT_APP_ACCOUNT_RESOURCE_PATH
  public static axiosInstance = ( () => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    instance.interceptors.response.use(response => response, error => {
      if (error.response.status === 401) {
        store.dispatch(signOutThunk())
      }
    })
    return instance
  })()

  public static SignUp<T = any>(input: SignupInput) {
    return this.axiosInstance.post<T>(`${this.AUTH_PATH}/accounts`, input)
  }

  public static SignIn<T = any>(input: SigninInput) {
    return this.axiosInstance.post<T>(`${this.AUTH_PATH}/accounts/signin`, input)
  }

  public static ActivateEmail<T = any>(input: ActivateEmailInput) {
    return this.axiosInstance.post<T>(`${this.AUTH_PATH}/accounts/activate`, input)
  }

  public static ResetPassword<T = any>(input: ResetPasswordInput) {
    return this.axiosInstance.post<T>(`${this.AUTH_PATH}/accounts/resetPassword`, input)
  }

  public static RequestPasswordReset<T = any>(input: ResetPasswordRequestInput) {
    return this.axiosInstance.post<T>(`${this.AUTH_PATH}/accounts/requestPasswordReset`, input)
  }

  public static GetMyDeployments<T = any>() {
    return this.axiosInstance.get<T>(`${this.ACCOUNT_RESOURCE_PATH}/deployments/mine`, {headers: {...this.withAuthorization()}})
  }

  public static CreateDeployment<T = any>(input: CreateDeploymentInput) {
    return this.axiosInstance.post<T>(`${this.ACCOUNT_RESOURCE_PATH}/deployments`, input, {headers: {...this.withAuthorization()}})
  }

  public static GetMyMeasurementSpecs<T = any>() {
    return this.axiosInstance.get<T>(`${this.ACCOUNT_RESOURCE_PATH}/amds/configurations/mine`, {headers: {...this.withAuthorization()}})
  }

  public static CreateMeasurementSpec<T = any>(input: CreateMeasurementSpecInput) {
    return this.axiosInstance.post<T>(`${this.ACCOUNT_RESOURCE_PATH}/amds/configurations`, input, {headers: {...this.withAuthorization()}})
  }

  public static GetAMDsByDeploymentId<T = any>(deploymentId: string) {
    return this.axiosInstance.get<T>(`${this.ACCOUNT_RESOURCE_PATH}/deployments/${deploymentId}/amds`, {headers: {...this.withAuthorization()}})
  }

  public static ClaimAmd<T = any>(input: ClaimAMDInput) {
    return this.axiosInstance.patch<T>(`${this.ACCOUNT_RESOURCE_PATH}/amds/claim`, input, {headers: {...this.withAuthorization()}})
  }

  public static GetMyAMDsSummary<T = any>() {
    return this.axiosInstance.get<T>(`${this.ACCOUNT_RESOURCE_PATH}/amds/mine/summary`, {headers: {...this.withAuthorization()}})
  }

  public static UnclaimAMDByIdPhrase<T = any>(idPhrase: string) {
    return this.axiosInstance.post<T>(`${this.ACCOUNT_RESOURCE_PATH}/amds/${idPhrase}/unclaim`, {}, {headers: {...this.withAuthorization()}})
  }

  public static AMDCSVExport<T = any>() {
    return this.axiosInstance.get<T>(`${this.ACCOUNT_RESOURCE_PATH}/amds/measurements`, {headers: {...this.withAuthorization(), 'Accept': 'text/csv'}})
  }

  private static withAuthorization() {
    return {'Authorization': `Bearer ${store.getState().user.loginToken}`}
  }
}
