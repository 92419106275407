import { InfoActionCard } from '../../../../components/Cards/InfoActionCard'
import { SectionHeading } from '../../../../components/Text/SectionHeading'
import React, { FC, useEffect, useState } from 'react'
import { CardParagraph } from '../../../../components/Text/CardParagraph'
import { FormControlSingleRow } from '../../../../components/Form/FormControlSingleRow'
import { Box, MenuItem, SelectChangeEvent } from '@mui/material'
import { StyledFormLabel } from '../../../../components/Form/StyledFormLabel'
import { StyledSelect } from '../../../../components/Select/StyledSelect'
import { InlineAsterisk } from '../../../../components/Text/InlineAsterisk'
import { MeasurementSpecOutput } from '@/models/MeasurementSpec/output/measurementSpec.output'
import { StyledButton } from '../../../../components/Buttons/StyledButton'
import { MeasurementSpecStepModal } from './MeasurementSpecStepModal'
import API from '../../../../api'
import { APIResponse } from '@/types/API/response.output'
import { ArrayOutput } from '@/types/API/array.output'

interface MeasurementSpecStepProps {
  targetMeasurementSpecState: [MeasurementSpecOutput | '', React.Dispatch<React.SetStateAction<MeasurementSpecOutput | ''>>]
  setContinueEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

export const MeasurementSpecStep: FC<MeasurementSpecStepProps> = (props) => {
  const { targetMeasurementSpecState: [targetMeasurementSpec, setTargetMeasurementSpec], setContinueEnabled } = props

  const measurementSpecOutputsState = useState<Array<MeasurementSpecOutput>>([])
  const [measurementSpecOutputs, setMeasurementSpecOutputs] = measurementSpecOutputsState

  const [fetchingMeasurementSpecs, setFetchingMeasurementSpecs] = useState(false)

  const modalOpenState = useState(false)
  const [, setModalOpen] = modalOpenState

  const handleSetMeasurementSpec = (event: SelectChangeEvent<unknown>) => {
    setTargetMeasurementSpec(event.target.value as MeasurementSpecOutput)
  }

  const handleNewMeasurementSpec = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    setFetchingMeasurementSpecs(true)
    API.GetMyMeasurementSpecs<APIResponse<ArrayOutput<MeasurementSpecOutput>>>()
      .then((response) => {
        setMeasurementSpecOutputs(() => [...response.data.data.items])
        setFetchingMeasurementSpecs(false)
      })
  }, [setMeasurementSpecOutputs])

  // control the outer PRIMARY CTA
  useEffect(() => {
    setContinueEnabled(targetMeasurementSpec === '' ? false : true)
  }, [setContinueEnabled, targetMeasurementSpec])

  return (
    <>
      <InfoActionCard>
        <SectionHeading size='h6'>Measurement Specification</SectionHeading>
        <CardParagraph sx={{ marginTop: '16px' }}>AMDs will take measurements and transmit payloads at specified intervals. Please select an existing specification or create a new one. You can change these later.</CardParagraph>
        <FormControlSingleRow>
          <Box component='div' sx={{ gridArea: 'left' }}>
            <StyledFormLabel id='deployment-select-label'>Specification<InlineAsterisk /></StyledFormLabel>
            <StyledSelect
              renderValue={(value) => {
                const measurementSpec = value as MeasurementSpecOutput | ''
                if (measurementSpecOutputs?.length > 0) {
                  if (measurementSpec !== '') {
                    return measurementSpec.label
                  }
                  return 'Select Specification'
                }
                return 'No Specifications'
              }}
              loading={fetchingMeasurementSpecs}
              disabled={measurementSpecOutputs?.length === 0 || fetchingMeasurementSpecs}
              labelId='deployment-select-label'
              displayEmpty
              sx={{ height: '40px', borderRadius: '10px', position: 'relative' }}
              value={targetMeasurementSpec}
              onChange={handleSetMeasurementSpec}
            >
              <MenuItem disabled>Please Select a Specification</MenuItem>
              {measurementSpecOutputs?.map((measurementSpecOutput) => (
                <MenuItem key={measurementSpecOutput._id} value={measurementSpecOutput as any}>{measurementSpecOutput.label}</MenuItem>
              ))}
            </StyledSelect>
          </Box>
          <StyledButton onClick={handleNewMeasurementSpec} variant='outlined' sx={{ gridArea: 'right', position: 'absolute', width: '100%', bottom: '0px' }}>
            New Specification +
          </StyledButton>
        </FormControlSingleRow>
      </InfoActionCard>
      <MeasurementSpecStepModal
        measurementSpecOutputsState={measurementSpecOutputsState}
        setTargetMeasurementSpec={setTargetMeasurementSpec}
        modalOpenState={modalOpenState}
        setFetchingMeasurementSpecs={setFetchingMeasurementSpecs}
      />
    </>
  )
}