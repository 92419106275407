import { ContentTemplate } from '../../components/Templates/ContentTemplate'
import { FC } from 'react'
import { Box, Typography } from '@mui/material'

export const Deployments: FC = () => {
  return (
    <ContentTemplate title='Deployments'>
      <Box component='div' sx={{ marginY: '30px', marginX: '16px' }}>
      <Typography variant='h6' component='div' sx={{ paddingBottom: '12px' }}>Deployments</Typography>
        <Typography variant='body1' component='div' sx={{ paddingBottom: '26px', fontWeight: 'bold' }}>Currently under construction, coming soon...</Typography>
      </Box>
    </ContentTemplate>
  )
}