import { Box, styled } from '@mui/material'
import { FC } from 'react'
import { Outlet } from 'react-router'
import { Content } from './Content'
import { Header } from './Header'
import { Sidebar } from './Sidebar'

export const Dashboard: FC = () => (
    <DashboardLayout>
      <Header />
      <Sidebar />
      <Content>
        <Outlet />
      </Content>
    </DashboardLayout>
  )

const DashboardLayout = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas:
    `'header  header '
     'sidebar content'`,
  gridTemplateRows: '56px auto',
  gridTemplateColumns: '225px auto',
  position: 'relative',
  minHeight: '100%',
  height: '100vh',
  backgroundColor: theme.dashboard.bg
}))