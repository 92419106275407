import { ContentTemplate } from '../../components/Templates/ContentTemplate'
import { FC, useState } from 'react'
import { ProgressionStepper } from '../../components/Stepper/ProgressionStepper'
import { Box, Link, styled, Typography } from '@mui/material'
import { StyledButtonPrimary, StyledButtonSecondary } from '../../components/Buttons/CTA'
import { useNavigate } from 'react-router'
import { DeploymentStep } from './Steps/DeploymentStep/DeploymentStep'
import { GatewaysStep } from './Steps/GatewaysStep/GatewaysStep'
import { MeasurementSpecStep } from './Steps/MeasurementSpecStep/MeasurementSpecStep'
import { DeploymentOutput } from '@/models/Deployment/output/deployment.output'
import { MeasurementSpecOutput } from '@/models/MeasurementSpec/output/measurementSpec.output'
import { AMDsStep } from './Steps/AMDsStep/AMDsStep'
import { SectionHeading } from '../../components/Text/SectionHeading'
import { OpenInNew } from '@mui/icons-material'


export const Setup: FC = () => {
  const navigate = useNavigate()

  const [continueEnabled, setContinueEnabled] = useState<boolean>(false)

  const targetDeploymentState = useState<DeploymentOutput | ''>('')
  const targetMeasurementSpecState = useState<MeasurementSpecOutput | ''>('')

  const steps: [string, JSX.Element][] = [
    ['Configure Deployment', <DeploymentStep setContinueEnabled={setContinueEnabled} targetDeploymentState={targetDeploymentState} />],
    ['Configure Gateway(s)', <GatewaysStep setContinueEnabled={setContinueEnabled} />],
    ['Measurement Spec', <MeasurementSpecStep setContinueEnabled={setContinueEnabled} targetMeasurementSpecState={targetMeasurementSpecState} />],
    ['Add AMD(s)', <AMDsStep setContinueEnabled={setContinueEnabled} targetDeploymentState={targetDeploymentState} targetMeasurementSpecState={targetMeasurementSpecState} />],
    ['Finished', <Box />]
  ]

  const [activeStep, setStep] = useState(2)

  const nextStep = () => {
    const nextStep = activeStep + 1
    if (steps[nextStep] === undefined) return
    setStep(nextStep)
  }

  const handleContinue = () => {
    setContinueEnabled(false)
    nextStep()
  }

  const handleCancel = () => {
    navigate('Overview')
  }

  return (
    <ContentTemplate title='AMD Setup'>
      { steps[activeStep][0] !== 'Finished'
        ? (
          <>
            <Box component='div' sx={{ paddingY: '30px', paddingX: '16px' }}>
              <ProgressionStepper sx={{ width: '50%' }} steps={steps.filter(step => step[0] !== 'Finished').map(step => step[0])} activeStep={activeStep} />
            </Box>
            { steps[activeStep][1] }
            <CtaBox component='div' sx={{ }}>
              <StyledButtonPrimary disabled={!continueEnabled} onClick={handleContinue} variant='contained' sx={{ marginX: '1%', height: '40px' }}>Continue</StyledButtonPrimary>
              <StyledButtonSecondary onClick={handleCancel} variant='outlined' sx={{ marginX: '1%', height: '40px' }}>Cancel</StyledButtonSecondary>
            </CtaBox>
          </>
        ) :
          <Box component='div' sx={{ marginTop: '32px', marginLeft: '16px', width: '60%' }}>
            <SectionHeading size='h5'>Deployment Complete</SectionHeading>
            <Typography variant='body1' sx={{ marginTop: '16px', marginLeft: '16px'}}>Your AMDs are now deployed. To activate them, please refer to the{' '}
              <Link
                target='_blank'
                href='https://uploads-ssl.webflow.com/5dad62f8508f04e6b403f37f/62aa557acbe4382fefea26a9_AMD%20Data%20Sheet.pdf'>
                  Datasheet<OpenInNew fontSize='inherit' />
              </Link>.</Typography>
              <Typography variant='body1' sx={{ marginTop: '16px', marginLeft: '16px'}}>You may view your newly created AMD(s) on the <Link href={'/AMDs'}>AMDs Page</Link>.</Typography>
          </Box>
      }
    </ContentTemplate>
  )
}

const CtaBox = styled(Box)(({theme}) => ({
  marginTop: '12px',
  padding: '12px',
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '80%',
  },
  [theme.breakpoints.up('xxl')]: {
    width: '70%',
  },
  display: 'flex',
  flexDirection: 'row',
}))
