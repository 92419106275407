import { UserState } from "./store/User/user.slice"

const topNamespace = 'zoneos'
enum ModelNamespace {
    Account = 'account'
}
const accountSchema = {
    name: {
        type: 'string',
        target: `${topNamespace}:${ModelNamespace.Account}:name`,
    },
    email: {
        type: 'string',
        target: `${topNamespace}:${ModelNamespace.Account}:email`,
    },
    jwt: {
        type: 'string',
        target: `${topNamespace}:${ModelNamespace.Account}:jwt`
    },
}

export class LocalStorageDriver {

    public static getAccountData = (): Pick<UserState, 'name' | 'email' | 'loginToken'> | null => {
        const name = localStorage.getItem(accountSchema.name.target) ?? undefined
        const email = localStorage.getItem(accountSchema.email.target) ?? undefined
        const loginToken = localStorage.getItem(accountSchema.jwt.target) ?? undefined
        if (!name || !email || !loginToken) return null
        return {name, email, loginToken}
    }
    public static setAccountData = (name: string, email: string, loginToken: string) => {
        localStorage.setItem(accountSchema.name.target, name)
        localStorage.setItem(accountSchema.email.target, email)
        localStorage.setItem(accountSchema.jwt.target, loginToken)
    }
    public static clearAccountData = () => {
      localStorage.removeItem(accountSchema.name.target)
      localStorage.removeItem(accountSchema.email.target)
      localStorage.removeItem(accountSchema.jwt.target)
    }
}