import { AddActionCard } from '../../components/Cards/AddActionCard'
import { Box, CircularProgress, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import topology from '../../images/Setup/topology.png'
import { useNavigate } from 'react-router'
import { ModalContent } from '../../components/Modal/ModalContent'
import { CardParagraph } from '../../components/Text/CardParagraph'
import { ContentTemplate } from '../../components/Templates/ContentTemplate'
import { useTypedDispatch, useTypedSelector } from '../../state/hooks'
import { dismissWelcomeModal } from '../../state/store/User/user.slice'
import API from '../../api'
import { APIResponse } from '@/types/API/response.output'
import { ArrayOutput } from '@/types/API/array.output'


export const Overview: FC = () => {
  const navigate = useNavigate()
  const touchedWelcomeScreen = useTypedSelector((state) => state.user.dismissedWelcomeModal)
  const [fetchingAMDsSummary, setFetchingAMDsSummary] = useState(false)
  const [AMDSummaryOutputs, setAMDSummaryOutputs] = useState<any[]>([])
  const dispatch = useTypedDispatch()

  const handleAddAMDs = () => {
    navigate('/setup')
  }

  const handleClose = () => {
    dispatch(dismissWelcomeModal())
  }
  useEffect(() => {
    setFetchingAMDsSummary(true)
    API.GetMyAMDsSummary<APIResponse<ArrayOutput<[]>>>()
      .then((response) => {
        setAMDSummaryOutputs(() => [...response.data.data.items])
        setFetchingAMDsSummary(false)
      })
  }, [])
  return (
    <ContentTemplate title='Overview'>
      <Box component='div' sx={{ paddingY: '30px', paddingX: '16px' }}>
        <Typography variant='h6' component='div' sx={{ paddingBottom: '12px' }}>AMDs</Typography>
        { fetchingAMDsSummary
          ? <CircularProgress color='inherit' />
          : <Typography variant='body1' component='div' sx={{ paddingBottom: '26px', fontWeight: 'bold' }}>You currently have {AMDSummaryOutputs.length === 0 ? 'no' : `${AMDSummaryOutputs.length}`} AMD{AMDSummaryOutputs.length !== 1 && 's'} associated with your account.</Typography>
        }
        <AddActionCard />
      </Box>
      <ModalContent
        primaryText='Add AMDs'
        secondaryText='Dismiss'
        handlePrimary={handleAddAMDs}
        handleSecondary={handleClose}
        open={!touchedWelcomeScreen}
        handleClose={handleClose}
      >
        <Typography variant='h4' component='div' sx={{ width: '100%', textAlign: 'center', padding: '16px', marginTop: '24px' }}>Welcome to Zone OS</Typography>
        <Typography variant='body1' component='div' sx={{ width: '100%', textAlign: 'center', fontWeight: 'bold', paddingTop: '16px', paddingBottom: '32px' }}>Your account is now active</Typography>
        <Box component='div' sx={{ paddingX: '28px' }}>
          <CardParagraph>You can now proceed to add AMDs and Gateways to a <Box component='span' fontWeight='bold'>Deployment</Box> or dismiss this message and explore the platform.</CardParagraph>
          <CardParagraph>A <Box component='span' fontWeight='bold'>Deployment</Box> represents your main point of control and monitoring for one particular deployment of AMDs and Gateways. You will be creating one shortly while adding your AMDs.</CardParagraph>
          <CardParagraph>The diagram below shows the hierarchy of AMDs, Gateways and Installations and their simplified topology:</CardParagraph>
        </Box>
        <img alt='topology illustration' src={topology} style={{width: '100%'}} />
      </ModalContent>
    </ContentTemplate>
  )
}